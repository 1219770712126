.btn-top {
  text-align: center;
  border: 1px solid;
}

.btn-top img {
  width: 45px;
  height: 45px;
  padding: 2px;
}


.main {
  font-size: 40px;
}

.order-info {
  display: flex;
  justify-content: space-between;
}

.order-line-n {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.order-line {
  border-bottom: 1px solid;
  min-height: 2em;
}

.order-group {
  min-height: 3em;
  align-items: center;
}

.block-info {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #c9d7eb;
}

.strong {
  font-weight: 700;
}

.m1 {
  display: block;
  background-color: #1111118f;
}

.m1 .modal-dialog {
  max-width: 90%;
}

.tov-prop {
  max-width: 80%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.tov-orde {
  display: flex;
  justify-content: space-between;
  margin: 1.75rem auto;
}

.tov-btn {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
}

.tov-btn button {
  width: 100%;
  margin: 1rem;
}

.TextRed {
  color: #ff0000;
}

.order-info-klient {
  font-size: 1.1em;
  font-weight: 600;
}

.border-bottom {
  border-bottom: solid 1px !important;
}

input[type="checkbox"] {
  zoom: 1.5;
}

.titl-order {
  height: 100vh;
}

.titl {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titl-btn-blok {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 500px;
}

.cbtn-titl {
  height: 100px;
  width: 100%;
}

.cbtn-titl button {
  height: 100%;
  width: 100%;
}

.day-of-weak {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
}

.list-orde {
  width: 100%;
  height: 100%;
  overflow: auto;

}

.list-klient {
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: auto;
}
.list-debet {
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: auto;
  font-size: 12px;
}
option {
  font-size: 15px;
}

.list-klient .row:hover {
  background-color: #c9d7eb;
}

.list-klient .row {
  align-content: center;
  min-height: 3em;
}

.img-list-klient-orde {
  width: 45px;
}

.tab-2r {
  background-color: #d4efff94;
}

.set-titl {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.set-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

/* Context menu css */

nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: .2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  margin-left: 10px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .copy {
  color: skyblue
}

.react-contextmenu-item .delete {
  color: red
}

.react-contextmenu-item .send {
  color: blue
}

.react-contextmenu-item .share {
  color: green
}

.react-contextmenu-item .watchlist {
  color: rebeccapurple
}

.logon-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}



.bt-1 {
  border-top: solid 1px;
}

.bb-1 {
  border-bottom: solid 1px;
}

.br-1 {
  border-right: solid 1px;
}

.top-info {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}

.g-top-info {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffedc0
}

.order-group img{
  height: 18px;
  margin-right: 5px;
}

.isCheck{
  background-color: #c0ffc5c2;
}
.isDelete{
  background-color: #ffc0c0c2;
}
.isDraft{
  background-color: #fff3c0c2;
}

.font-weight-light {
  font-weight: 300;
}
.font-italic {
  font-style: italic;
}

.butn {
  border-color: #3498db;
  color: #fff;
  box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #3498db;
  transition: all 150ms ease-in-out;


}
.butn:hover {
  box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
  color: #fff;
  outline: 0;
}

.container{
  max-width: 720px;
}

.grkl{
  font-weight: 700;
  background-color: #dadeff;
}
.form-group input{
  margin-top: 5px;
}
